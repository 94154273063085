import { o_util } from "@gr-common/head/namespaces";
const { cookie } = o_util;
const { exists, get, remove, set } = cookie ?? {};
export {
  cookie,
  exists,
  get,
  remove,
  set
};
